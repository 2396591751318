import { ApiAgendaService } from './agenda.service';
import { ApiAppointmentService } from './appointment.service';
import { ApiClientService } from './client.service';
import { ApiCommunicationService } from './communication.service';
import { ApiExceptionService } from './exception.service';
import { ApiImageService } from './image.service';
import { ApiLinkService } from './link.service';
import { ApiContactService } from './contact.service';
import { ApiNoteService } from './note.service';
import { ApiPairingService } from './pairing.service';
import { ApiReservationService } from './reservation.service';
import { ApiResourceScheduleService } from './resource.schedule.service';
import { ApiResourceService } from './resource.service';

export {
    ApiAgendaService,
    ApiAppointmentService,
    ApiClientService,
    ApiCommunicationService,
    ApiContactService,
    ApiExceptionService,
    ApiImageService,
    ApiLinkService,
    ApiNoteService,
    ApiPairingService,
    ApiReservationService,
    ApiResourceScheduleService,
    ApiResourceService
}