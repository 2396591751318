import {include} from 'named-urls';

export default {
    home: '/',
    dashboard: '/',

    noaccess: '/401-no-access',
    forbidden: '/403-forbidden',
    notfound: '/404-not-found',

    error: '/500-error',

    auth: {
        login: '/login',
        forgotpassword: '/forgot-password',
        resetpasswordcode: '/reset-password/:code',
        resetpassword: '/reset-password',
        twofactor: '/two-factor'
    },

    agenda: {
        myagenda: '/agenda/:agenda?/:date?',
        group: '/groupoverview/:date?',
        subgroup: '/subgroup/:subgroup?/:date?',
        resources: '/resource-overview/:date?',

        history: '/history/:date?/:start?/:end?/:agenda?',
        searchfreeslot: '/searchfreeslot',
        conflicts: '/conflicts/:agenda?/:ids?',

        printWeek: '/agenda/print/week/:date/:agenda?/:category?',
        printDay: '/agenda/print/day/:date/:agenda?/:category?',

        appointmentCopy: '/agenda/copy/:appointment/:date/:agenda?',
        appointmentMove: '/agenda/move/:appointment/:date/:agenda?',

        appointmentMoveAgenda: '/groupoverview/move/:agenda/:appointment/:date',
        groupPrint: '/groupoverview/print/:date/:subgroup?',
        resourcePrint: '/resource-overview/print/:date/'
    },

    resource: include('/resource', {
        single: ':id',
        group: 'all'
    }),

    appointments: include('/appointments', {
        overview: '',
        new: 'new/:agenda/:start?/:end?',
        add: 'add/:agenda/:start?/:end?/:category?/:slot?/:subcategory?/:force?',
        edit: 'edit/:agenda/:id',
        remove: 'remove/:id',
        print: 'print/:id',
        search: 'search/:searchStr/:agenda?',
        statistics: include('statistics', {
            overview: ''
        })
    }),

    lastminutes: include('/lastminutes', {
        overview: '',
        add: 'add/:agenda/:start?/:end?/:category?/:slot?/:subcategory?/:force?',
    }),

    notes: include('/notes', {
        overview: '',
        add: 'add/:agenda/:start/:allDay?',
        edit: 'edit/:agenda/:id'
    }),

    homevisits: include('/homevisit', {
        overview: ':agenda?/:date?',
        add: 'add/:date?/:agenda?/:time?/:category?',
        edit: 'edit/:id/:applicableForIgnore?',
        printDay: 'print/:agenda?/:date?'
    }),

    dayappointments: include('/dayappointments', {
        overview: '',
        add: 'add/:agenda?/:date?',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    contacts: include('/contacts', {
        overview: ':search?',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id',
        appointments: 'appointments/:id/:repeated?',
        mail: 'mail/:id',
        sms: 'sms/:id',
        confirm: 'confirm'
    }),

    holidays: include('/settings/holidays', {
        overview: ':type?',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id',
        holidays: 'holidays'
    }),

    profiles: include('/settings/profiles', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        compose: 'compose/:id/:wizard?',
        priority: 'priority/:id',
        homevisit: 'homevisit/:id',
        remove: 'remove/:id',
        periods: include('periods/:scheduleId', {
            overview: '',
            add: 'add/:wizard?',
            edit: 'edit/:id',
            remove: 'remove/:id'
        })
    }),

    resources: include('/settings/resources', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id',
        schedules: include(':resource/schedules', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',
            compose: ':schedule/compose',
            periods: include(':schedule/periods', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id'
            })
        })
    }),

    appointmentstatuses: include('/settings/appointmentstatuses', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    exceptions: include('/settings/exceptions', {
        overview: ':type?',
        add: 'add/:date?/:slot?/:agenda?',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    blockings: include('/settings/blockings', {
        overview: ':date?',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    categories: include('/settings/categories', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    subcategories: include('/settings/subcategories', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    forms: include('/settings/forms', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id',

        fields: include(':form/field', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',
        })
    }),

    tags: include('/settings/tags', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    settings: include('/settings', {
        group: include('group', {
            contact: 'contact',
            sort: 'sort',
            agenda: 'agenda',
            profile: 'profile'
        }),
        personal: 'personal',
        agenda: 'agenda',
        links: include('links', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        account: include('account', {
            profile: 'profile',
            authentication: 'authentication/:tab?'
        }),
        pairing: include('pairing', {
            overview: ''
        }),
        arrangements: include('arrangements', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id',
            staffels: 'staffels/:id',
            options: include('options', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id'
            }),
            lastminutes: include('lastminutes', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
                staffels: 'staffels/:id',
                templates: include('templates', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id',
                    staffels: 'staffels/:id',
                })
            })
        })
    }),

    // Order routes
    shop: include('/shop', {
        overview: '',
        products: include('products', {
            overview: '',
            add: 'add/:category?',
            edit: 'edit/:id',
            remove: 'remove/:id',
            category: 'category/:id',

            categories: include('categories', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
                activate: 'activate/:id',
                products: ':id'
            })
        }),

        orders: include('orders', {
            overview: '',
            overviewPerDay: 'per-day/:date?',
            overviewProductTotalsPerDay: 'product-totals-per-day',
            detail: 'detail/:id',
            edit: 'edit/:id',
            export: 'export'
        }),
        cashdesk: include('cashdesk', {
            overview: ':order'
        })
    }),

    giftvouchers: include('/giftvouchers', {
        overview: '',
        add: 'add',
        addBulk: 'addBulk',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    transactions: include('/transactions', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    subgroups: include('/settings/subgroups', {
        overview: '',
        add: 'add',
        edit: 'edit/:id',
        remove: 'remove/:id'
    }),

    communication: include('/communication', {
        mailbox: include('mailbox', {
            inbox: ':message?',
            compose: 'compose/:message?/:all?'
        }),
        tasks: include('tasks', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        reminders: include('reminders', {
            overview: '',
            add: 'add',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        popups: include('popups', {
            overview: '',
            add: 'add/:agenda?',
            edit: 'edit/:id',
            remove: 'remove/:id'
        }),
        mailing: include('mailing', {
            campaign: include('campaign', {
                overview: '',
                add: 'add',
                copy: 'copy/:id',
                edit: 'edit/:id',
                remove: 'remove/:id',
                schedule: include(':campaign', {
                    overview: 'schedule/:tab?',
                    statistics: 'statisctics/:schedule'
                })
            }),
            list: include('list', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',
                member: include(':list/members', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id'
                })
            }),
        }),
        mail: include('mail', {
            compose: '',
            templates: include('templates', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',

                timings: include(':template/timings', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id'
                })
            })
        }),
        sms: include('sms', {
            compose: '',
            numbers: 'numbers',
            templates: include('templates', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id',

                timings: include(':template/timings', {
                    overview: '',
                    add: 'add',
                    edit: 'edit/:id',
                    remove: 'remove/:id'
                })
            })
        }),
    }),

    // Reservation routes
    reservation: include('/reservation', {
        reservations: include('reservations', {
            overview: ':date?',
            compact: 'compact/:date?',
            detail: 'detail/:id',
            add: 'add/:date?',
            edit: 'edit/:id',
            print: 'print/:id'
        }),

        /*settings: include('settings', {
            group: 'group',
            location: 'location',
            workload: include('workload', {
                overview: '',
                add: 'add',
                edit: 'edit/:id',
                remove: 'remove/:id'
            }),
            links: 'links'
        })*/
    }),

    e: include('/e', {
        al: ':path',
        c: 'c/:path',
        g: 'g/:path',
        t: 't/:path',
        tc: 'tc/:path',
        tg: 'tg/:path',
        tgm: 'tgm/:path',
        gc: 'gc/:path',
        lm: 'lm/:path',
        custom: 'custom/:path'
    })

    // e: '/e/:type/:path'
    // contactApp: '/e/:type/:path'
}