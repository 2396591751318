import React, { Component } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';

import SidebarRun from './Sidebar.run';
import SidebarUserBlock from './SidebarUserBlock';

import CalendarSidebar from '../sidebar/CalendarSidebar';
import { UtilSidebar } from '../../utils/sidebar.util';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item, t }) => (
    <li className="nav-heading">
        <span>{t(item.translate)}</span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive, t, notifications, collapsed }) => (
    <li className={isActive ? 'active' : ''}>
        <Link to={item.path.replace(/\/$/, '')} title={t(item.translate)}>
            {item.label && notifications && notifications[item.label] !== undefined && notifications[item.label] !== null && notifications[item.label] !== 0 &&
                <Badge tag="div" className="float-right" color="danger">{notifications[item.label]}</Badge>}
            {item.icon && <em className={'nav-item-icon ' + item.icon}></em>}
            {!collapsed && <span>{t(item.translate)}</span>}
        </Link>
    </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, t, handler, children, notifications }) => (
    <li className={isActive ? 'active' : ''}>
        <div className="nav-item" onClick={handler}>
            {item.label && notifications && notifications[item.label] !== undefined && notifications[item.label] !== null &&
                <Badge tag="div" className="float-right" color="danger">{notifications[item.label]}</Badge>}
            {item.icon && <em className={'nav-item-icon ' + item.icon}></em>}
            <span>{t(item.translate)}</span>
        </div>
        <Collapse isOpen={true}>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                {children}
            </ul>
        </Collapse>
    </li>
)

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)

class Sidebar extends Component {

    state = {
        collapse: {}
    }

    componentDidMount() {
        // pass navigator to access router api
        SidebarRun(this.navigator, false); //this.closeSidebar);
        // prepare the flags to handle menu collapsed states
        this.buildCollapseList()

        // Listen for routes changes in order to hide the sidebar on mobile
        //this.props.history.listen(this.closeSidebar);
    }

    closeSidebar = () => {
        this.props.actions.toggleSetting('asideToggled');
    }

    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = () => {
        let menuSidebar = [];
        if (this.props.location.pathname.startsWith('/settings/')) {
            menuSidebar = UtilSidebar.asembleSettingSidebar(this.props.group, this.props.agenda, this.props.client);
        } else if (this.props.location.pathname.startsWith('/communication/')) {
            menuSidebar = UtilSidebar.asembleCommunicationSidebar(this.props.group, this.props.agenda, this.props.client);
        } else if (this.props.location.pathname.startsWith('/shop/') || this.props.location.pathname.startsWith('/giftvoucher/')) {
            menuSidebar = UtilSidebar.asembleSidebarShop(this.props.group);
        } else {
            menuSidebar = UtilSidebar.asembleSidebar(this.props.group, this.props.client, this.props.notifications);
        }

        let collapse = {};
        menuSidebar
            .filter(({ heading }) => !heading)
            .forEach(({ name, path, submenu }) => {
                collapse[name] = this.routeActive(submenu ? submenu.map(({ path }) => path) : path)
            })
        this.setState({ collapse });
    }

    navigator = route => {
        this.props.history.push(route);
    }

    routeActive(paths) {
        paths = Array.isArray(paths) ? paths : [paths];
        return paths.some(path => {
            if (path.startsWith('/agenda')) {
                return path === this.props.location.pathname;
            } else {
                let pathName = this.props.location.pathname + '/';
                return pathName.indexOf(path) > -1;
            }
        });
    }

    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    getSubRoutes = item => item.submenu.map(({ path }) => path)

    /** map menu config to string to determine which element to render */
    itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    render() {
        const { settings, group, client, notifications, agenda } = this.props;

        let isSettingMenu = this.props.location.pathname.startsWith('/settings/');
        let isShopMenu = this.props.location.pathname.startsWith('/shop/');
        let isVoucherMenu = this.props.location.pathname.startsWith('/giftvouchers');
        let isTransactionMenu = this.props.location.pathname.startsWith('/transactions');
        let isCommunicationMenu = this.props.location.pathname.startsWith('/communication/');
        let menuSidebar = [];

        switch (settings.activeModule) {
            case 'agenda':

                if (isSettingMenu || isShopMenu || isVoucherMenu || isTransactionMenu) {
                    menuSidebar = UtilSidebar.asembleSettingSidebar(group, agenda, client);
                } else if (isCommunicationMenu) {
                    menuSidebar = UtilSidebar.asembleCommunicationSidebar(group, agenda, client);
                } else {
                    menuSidebar = UtilSidebar.asembleSidebar(group, client, notifications);
                }
                break;
            case 'reservation':
                if (isCommunicationMenu) {
                    menuSidebar = UtilSidebar.asembleCommunicationSidebar(group, agenda, client);
                } else {
                    menuSidebar = UtilSidebar.asembleReservationSidebar(group);
                }
                break;
            case 'shop':
                menuSidebar = UtilSidebar.asembleSidebarShop(group);
                break;
            default:
                menuSidebar = [];
                break;
        }

        return (
            <aside className='aside-container'>
                { /* START Sidebar (left) */}
                <div className="aside-inner">
                    <nav data-sidebar-anyclick-close="" className="sidebar">
                        { /* START sidebar nav */}
                        <ul className="sidebar-nav">
                            { /* START user info */}
                            <li className="has-user-block">
                                <SidebarUserBlock {...this.props} />
                            </li>
                            { /* END user info */}

                            {
                                client && !settings.isCollapsedText && !settings.isCollapsed && !isSettingMenu && (
                                    <CalendarSidebar {...this.props} />
                                )
                            }

                            {notifications && 'solutelMessages' in notifications && (
                                <li>
                                    <Link className="text-warning" to={{ pathname: 'https://www.solucall.be' }}
                                        target="_blank"
                                        title={t('sidebar:solucall', { count: notifications.solutelMessages })}>
                                        <em className="nav-item-icon fa fa-circle text-warning"></em>
                                        <span>{t('sidebar:solucall', { count: notifications.solutelMessages })}</span>
                                    </Link>
                                </li>
                            )}

                            { /* Iterates over all sidebar items */}
                            {
                                menuSidebar.map((item, i) => {
                                    // heading
                                    if (this.itemType(item) === 'heading')
                                        return (
                                            <SidebarItemHeader item={item} t={t} key={i} />
                                        )
                                    else {
                                        if (this.itemType(item) === 'menu')
                                            return (
                                                <SidebarItem isActive={this.routeActive(item.path)} item={item} t={t}
                                                    key={i}
                                                    collapsed={settings.isCollapsedText && settings.isCollapsed}
                                                    notifications={this.props.notifications} />
                                            )
                                        if (this.itemType(item) === 'submenu')
                                            return [
                                                <SidebarSubItem item={item} t={t}
                                                    isOpen={this.state.collapse[item.name]}
                                                    handler={this.toggleItemCollapse.bind(this, item.name)}
                                                    isActive={this.routeActive(this.getSubRoutes(item))}
                                                    key={i} notifications={this.props.notifications}>
                                                    <SidebarSubHeader item={item} key={i} />
                                                    {
                                                        item.submenu.map((subitem, i) =>
                                                            <SidebarItem key={i} item={subitem}
                                                                isActive={this.routeActive(subitem.path)} t={t}
                                                                notifications={this.props.notifications} />
                                                        )
                                                    }
                                                </SidebarSubItem>
                                            ]
                                    }
                                    return null; // unrecognized item
                                })
                            }
                        </ul>
                        { /* END sidebar nav */}
                    </nav>
                </div>
                { /* END Sidebar (left) */}
            </aside>
        );
    }
}

Sidebar.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({
    group: state.group,
    agenda: state.agenda ? state.agenda.active : null,
    settings: state.settings,
    client: state.client ? state.client.logged_in : null,
    activeClient: state.client ? state.client.active : null,
    notifications: state.notifications,
})
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)((withRouter(Sidebar)));
