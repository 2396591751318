import routes from '../routing/routes';
import { reverse } from "named-urls";
import { UtilClient } from "./client.util";

const asembleSidebar = (group) => {
    /**
     * Order links
     * - mijn agenda
     * - (aangemaakte) groepen
     * - alle agenda's (groepsover alg)
     * - huisbezoeken
     * - individuele agenda's
     * - rest
     */
    let menu = [];

    let clientHasRestrictedAccess = false; // UtilClient.hasRestrictedAccess(loggedinClient);
    let clientHasAccessToSettings = true; // UtilClient.hasAccessToSettings();

    let agendaMenu = {
        name: 'Agenda',
        icon: 'icon-calendar',
        translate: 'sidebar:agenda:main',
        submenu: []
    };

    if (group && group.agendas && group.agendas.forEach((agenda) => {
        agendaMenu['submenu'].push({
            name: agenda.title,
            translate: agenda.title,
            path: reverse(routes.agenda.myagenda, { agenda: agenda.id })
        });
    }));

    if (!clientHasRestrictedAccess) {
        agendaMenu['submenu'].push({
            name: 'Zoek vrij tijdstip',
            translate: 'sidebar:agenda:searchfreeslot',
            path: reverse(routes.agenda.searchfreeslot)
        });
    }

    if (group && group.subgroups && group.subgroups.length > 0) {
        group.subgroups.forEach((subgroup) => {
            if (!subgroup.agendas || subgroup.agendas.length <= 0) {
                return;
            }
            agendaMenu['submenu'].push({
                name: subgroup.name,
                translate: subgroup.name,
                path: reverse(routes.agenda.subgroup, { subgroup: subgroup.id })
            });
        });
    }

    agendaMenu['submenu'].push({
        name: 'Groepsoverzicht',
        translate: 'sidebar:agenda:groupoverview',
        path: reverse(routes.agenda.group)
    });

    if (group && group.resources && group.resources.length > 0) {
        agendaMenu['submenu'].push({
            name: 'Groepsoverzicht resources',
            translate: 'Groepsoverzicht resources',
            path: reverse(routes.agenda.resources)
        });
    }

    if (clientHasAccessToSettings) {
        agendaMenu['submenu'].push(
            {
                name: 'Dagafspraken',
                translate: 'sidebar:agenda:dayappointments',
                path: reverse(routes.dayappointments.overview)
            },
            {
                name: 'Historiek',
                translate: 'sidebar:agenda:history',
                path: reverse(routes.agenda.history)
            },
            {
                name: 'Conflicten',
                translate: 'sidebar:agenda:conflicts',
                path: reverse(routes.agenda.conflicts),
                label: 'appointmentConflicts'
            }
        );
    }

    menu.push(agendaMenu);


    menu.push({
        name: 'Contacten',
        icon: 'icon-people',
        translate: 'sidebar:contacts:main',
        path: reverse(routes.contacts.overview),
        label: 'contactsToApprove'
    });


    return menu;
};

const asembleSidebarShop = (group) => {
    let menu = [];

    if (group.modules.shop.giftVouchersActive) {
        let giftVoucherMenu = {
            name: 'Cadeaubonnen',
            icon: 'fa fa-gift',
            translate: 'sidebar:giftvouchers:main',
            submenu: [
                {
                    name: 'Overzicht',
                    translate: 'sidebar:giftvouchers:overview',
                    path: reverse(routes.giftvouchers.overview)
                }
            ]
        };
        menu.push(giftVoucherMenu);
    }

    return menu;
}


const asembleSettingSidebar = (group, agenda = null, loggedInClient = null) => {
    let menu = [];

    if (!agenda) {
        return menu;
    }

    let returnPath = null; // UtilUrl.buildViewAfterLogin(loggedInClient.settings, group);
    if (!returnPath) {
        if (agenda) {
            returnPath = reverse(routes.agenda.myagenda, { agenda: agenda.id });
        } else {
            returnPath = reverse(routes.agenda.group)
        }
    }

    let hasRestrictedAccess = UtilClient.hasRestrictedAccess(loggedInClient);

    menu.push({
        name: 'Terug naar agenda',
        icon: 'fa fa-arrow-left',
        translate: 'sidebar:settings:agenda-back',
        path: returnPath
    });

    let settingMenu = {
        name: 'Instellingen',
        icon: 'fa fa-cog',
        translate: 'sidebar:settings:main',
        submenu: []
    };

    settingMenu.submenu.push(
        {
            name: 'forms',
            translate: 'sidebar:settings:forms',
            path: routes.forms.overview
        },
        {
            name: 'categories',
            translate: 'sidebar:settings:categories',
            path: routes.categories.overview
        }
    );

    if (agenda) {
        settingMenu.submenu.push({
            name: 'profiles',
            translate: 'sidebar:settings:profiles',
            path: routes.profiles.overview
        });
    }

    settingMenu.submenu.push(
        {
            name: 'subcategories',
            translate: 'sidebar:settings:subcategories',
            path: routes.subcategories.overview
        },
        {
            name: 'appointmentstatuses',
            translate: 'sidebar:settings:appointmentstatuses',
            path: routes.appointmentstatuses.overview
        },
        {
            name: 'holidays',
            translate: 'sidebar:settings:holidays',
            path: reverse(routes.holidays.overview)
        }
    );

    if (agenda) {
        settingMenu.submenu.push(
            {
                name: 'exceptions',
                translate: 'sidebar:settings:exceptions',
                path: reverse(routes.exceptions.overview)
            },
            {
                name: 'blockings',
                translate: 'sidebar:settings:blockings',
                path: reverse(routes.blockings.overview)
            }
        );
    }

    settingMenu.submenu.push(
        {
            name: 'resources',
            translate: 'settings.resource:title',
            path: routes.resources.overview
        },
        {
            name: 'tags',
            translate: 'settings.tags:title',
            path: routes.tags.overview
        });

    if (group && group.type === 'wellness') {
        settingMenu.submenu.push(
            {
                name: 'arrangements',
                translate: 'settings.arrangement:title',
                path: routes.settings.arrangements.overview
            },
            {
                name: 'arrangement-lastminute-templates',
                translate: 'settings.arrangement:lastminutes:title',
                path: routes.settings.arrangements.lastminutes.templates.overview
            },
            {
                name: 'arrangement-options',
                translate: 'settings.arrangement:options:title',
                path: routes.settings.arrangements.options.overview
            }
        );
    }
    menu.push(settingMenu);

    if (group && group.type === 'wellness') {
        let shopMenu = {
            name: 'Shop',
            icon: 'fa fa-store',
            translate: 'sidebar:shop:main',
            submenu: [{
                name: 'products',
                translate: 'sidebar:shop:products',
                path: routes.shop.products.overview
            },
            {
                name: 'product-categories',
                translate: 'sidebar:shop:categories',
                path: routes.shop.products.categories.overview
            }]
        };

        menu.push(shopMenu);
    }

    if (group && group.type === 'wellness') {
        /*let voucherMenu = {
            name: 'GiftVouchers',
            icon: 'fa fa-tag',
            translate: 'sidebar:vouchers:main',
            submenu: [{
                name: 'vouchers',
                translate: 'sidebar:vouchers:vouchers',
                path: routes.giftvouchers.overview
            }]
        };

        menu.push(voucherMenu);*/
    }

    if (group && group.type === 'wellness') {
        let transactionMenu = {
            name: 'Transactions',
            icon: 'fa fa-money-bill',
            translate: 'sidebar:transactions:main',
            submenu: [{
                name: 'transactions',
                translate: 'sidebar:transactions:transactions',
                path: routes.transactions.overview
            }]
        };
        menu.push(transactionMenu);
    }

    let groupMenu = {
        name: 'Groep instellingen',
        icon: 'fa fa-users-cog',
        translate: 'sidebar:groupsettings:main',
        submenu: [{
            name: 'group',
            translate: 'sidebar:groupsettings:agenda',
            path: routes.settings.group.agenda
        }]
    };

    if (!hasRestrictedAccess) {
        groupMenu.submenu.push(
            {
                name: 'subgroups',
                translate: 'sidebar:groupsettings:subgroups',
                path: routes.subgroups.overview
            },
            {
                name: 'groupsort',
                translate: 'sidebar:groupsettings:sort',
                path: routes.settings.group.sort
            },
            {
                name: 'profile',
                translate: 'sidebar:groupsettings:profile',
                path: routes.settings.group.profile
            }
        );
    }
    menu.push(groupMenu);

    let agendaSettingsMenu = {
        name: 'Agenda instellingen',
        icon: 'fa fa-calendar-alt',
        translate: 'sidebar:agendasettings:main',
        submenu: [{
            name: 'agenda',
            translate: 'sidebar:agendasettings:agendapreferences',
            path: routes.settings.agenda
        },
        {
            name: 'links',
            translate: 'sidebar:agendasettings:links',
            path: routes.settings.links.overview
        },
        {
            name: 'pairing',
            translate: 'sidebar:agendasettings:pairing',
            path: reverse(routes.settings.pairing.overview)
        }]
    }
    menu.push(agendaSettingsMenu);

    let personalSettingMenu = {
        name: 'Persoonlijke instellingen',
        icon: 'fa fa-user-cog',
        translate: 'sidebar:personalsettings:main',
        submenu: [{
            name: 'profile',
            translate: 'sidebar:personalsettings:account',
            path: routes.settings.account.profile
        },
        {
            name: 'authentication',
            translate: 'sidebar:personalsettings:authentication',
            path: reverse(routes.settings.account.authentication)
        }]
    };
    menu.push(personalSettingMenu);

    return menu;
}

const asembleCommunicationSidebar = (group, activeAgenda = null, loggedInClient = null) => {
    let menu = [];

    /*if (!activeClient) {
        return menu;
    }*/

    let returnPath = null; //UtilUrl.buildViewAfterLogin(loggedInClient.settings, group);
    if (!returnPath) {
        returnPath = reverse(routes.agenda.myagenda, { agenda: activeAgenda ? activeAgenda.id : null });
    }

    menu.push({
        name: 'Terug naar agenda',
        icon: 'fa fa-arrow-left',
        translate: 'sidebar:settings:agenda-back',
        path: returnPath
    });

    let settingMenu = {
        name: 'Communicatie',
        icon: 'fa fa-bell',
        translate: 'sidebar:communication:title',
        submenu: []
    };

    settingMenu.submenu.push(
        {
            name: 'tasks',
            translate: 'sidebar:communication:tasks',
            path: routes.communication.tasks.overview,
            label: 'pendingTasks'
        },
        /*{
            name: 'messages',
            translate: 'sidebar:communication:messages',
            path: reverse(routes.communication.mailbox.inbox),
            label: 'unreadMessages'
        },*/
        /*{
            name: 'reminders',
            translate: 'sidebar:communication:reminders',
            path: routes.communication.reminders.overview,
            label: 'reminders'
        },*/
        {
            name: 'mails',
            translate: 'sidebar:communication:mails',
            path: routes.communication.mail.compose
        },
        /*{
            name: 'sms',
            translate: 'sidebar:communication:sms',
            path: routes.communication.sms.compose
        },*/
        {
            name: 'notifications',
            translate: 'sidebar:communication:notifications',
            path: routes.communication.popups.overview
        },
        /*{
            name: 'mailing',
            translate: 'sidebar:communication:mailing',
            path: routes.communication.mailing.campaign.overview
        }*/
    );

    menu.push(settingMenu);

    return menu;
}

const asembleReservationSidebar = () => {
    let menu = [
        {
            name: 'Reservaties',
            icon: 'icon-list',
            translate: 'sidebar:reservation:main',
            submenu: [{
                name: 'Overzicht',
                translate: 'sidebar:reservation:overview',
                path: reverse(routes.reservation.reservations.overview)
            }]
        },
        {
            name: 'Contacten',
            icon: 'icon-people',
            translate: 'sidebar:contacts:main',
            path: reverse(routes.contacts.overview),
            label: 'contactsToApprove'
        },
    ];


    return menu;
};

export const UtilSidebar = {
    asembleCommunicationSidebar,
    asembleSidebar,
    asembleSidebarShop,
    asembleSettingSidebar,
    asembleReservationSidebar
};