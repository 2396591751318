import { reverse } from "named-urls";
import routes from "../routing/routes";
import uuidv4 from 'uuid';
import moment from 'moment';

let _calendar = {};
const init = (calendar) => _calendar = calendar;

const renderEvents = (data, isResourceOverview = false) => {
    if (!data || !_calendar) return [];

    let { agenda, resource, slots, dayAppointments, lastminutes, notes, homeVisitData, canceled } = data;

    let events = [];
    const { filters } = _calendar.props;
    const { categories, subcategories } = _calendar.props.schemaData;

    if (canceled && canceled.length > 0) {
        canceled.forEach(event => {
            if (filters && filters.category && parseInt(filters.category) !== parseInt(event.category)) {
                return;
            }

            let category = categories[event.category];
            let color = "#000000";
            if (category) {
                color = category.bookedSlotBackground;
            }

            if (event.subcategory && event.subcategory in subcategories) {
                const subcategory = subcategories[event.subcategory];
                if (subcategory) {
                    color = subcategory.bookedSlotBackground ? subcategory.bookedSlotBackground : subcategory.freeSlotBackground;
                }
            }

            let remark = event.contactName;
            events.push({
                id: uuidv4(),
                title: remark,
                resourceId: event.agendaId,
                constraints: {
                    resourceIds: [event.agendaId]
                },
                start: event.start,
                end: event.end,
                borderColor: color,
                editable: false,
                extendedProps: {
                    blocked: false,
                    appointments: [],
                    isCanceled: true,
                    by: event.canceledBy,
                    date: event.canceledDate,
                    reason: event.canceledReason,
                    resourceId: event.agenda,
                    appointmentId: event.id
                }
            });
        });
    }

    if (slots && slots.length > 0) {
        const { filters } = _calendar.props;
        const { categories, subcategories } = _calendar.props.schemaData;
        let { modusParams } = _calendar.props.stateSettings;

        // Split multiple appointments to seperate events if only 1 appointments is bookable on slot
        let indexesToRemove = [];
        let newEvents = [];
        slots.forEach((event, index) => {
            if (event.appointments.length > 1 && event.appointmentsPerSlot <= 1) {
                indexesToRemove.push(index);

                let appointments = event.appointments;

                appointments.forEach(appointment => {
                    let newEvent = { ...event };
                    newEvent.remark = appointment.contactName;
                    newEvent.appointments = [appointment];

                    newEvents.push(newEvent);
                });
            }
        });

        for (let i = indexesToRemove.length - 1; i >= 0; i--) {
            slots.splice(indexesToRemove[i], 1);
        }

        slots = slots.concat(newEvents);

        // Create calender events
        slots.forEach(event => {
            // Check if slot category is filtered one
            if (filters && filters.category && parseInt(filters.category) !== parseInt(event.category)) {
                return;
            }

            // Check if slot priority is filtered one
            if (filters && filters.priority && !filters.priority.includes(parseInt(event.priority))) {
                return;
            }

            if (!agenda) {
                agenda = event.agenda;
            }
            event.agenda = agenda;

            let resourceId = isResourceOverview ? resource.id : event.agenda;

            let category = categories[event.category];

            let color = "#000000";
            if (category) {
                color = event.blocked ? category.bookedSlotBackground : category.freeSlotBackground;
            }

            if (event.subcategory) {
                const subcategory = subcategories[event.subcategory];
                if (subcategory) {
                    color = subcategory.bookedSlotBackground ? subcategory.bookedSlotBackground : subcategory.freeSlotBackground;
                }
            }

            let { blocked, blockReason } = event;
            if (['internal-not-bookable', 'internal-not-bookable-due-settings'].includes(blockReason)) {
                blocked = true;
            }

            let url = '';
            let addUrl = '';
            let remark = event.remark;
            let full = event.full;
            let maximumAppointments = event.appointmentsPerSlot ? event.appointmentsPerSlot : 1;

            if (!event.homeVisit && (!blocked || event.appointments.length > 1) && event.appointments.length < maximumAppointments) {
                url = reverse(routes.appointments.add, { agenda: agenda, start: moment(event.from).format('YYYYMMDDHHmm'), end: moment(event.till).format('YYYYMMDDHHmm'), category: event.category, slot: event.id });
            } else if (event.homeVisit) {
                let date = moment(event.from);
                let homeVisitEventData = null;
                let key = date.format('YYYY-MM-DD HH:mm');

                if (homeVisitData.hasOwnProperty(agenda)) {
                    homeVisitEventData = homeVisitData[agenda][key] ? homeVisitData[agenda][key] : null;
                } else {
                    homeVisitEventData = homeVisitData[key] ? homeVisitData[key] : null;
                }

                if (event.appointments.length <= 0) {
                    url = reverse(routes.homevisits.add, { date: date.format('YYYY-MM-DD'), agenda: agenda, time: date.format('HH:mm'), category: event.category });
                } else {
                    remark = '';
                    url = reverse(routes.homevisits.overview, { date: date.format('YYYY-MM-DD'), agenda: agenda });
                    addUrl = reverse(routes.homevisits.add, { date: date.format('YYYY-MM-DD'), agenda: agenda, time: date.format('HH:mm'), category: event.category });
                }

                if (homeVisitEventData) {
                    if (event.appointments.length > 0) {
                        let booked = homeVisitEventData.bookedVisits > event.appointments.length ? homeVisitData.bookedVisits : event.appointments.length;
                        remark = '[' + booked + '/' + homeVisitEventData.maxVisits + ']';
                    }
                    full = homeVisitEventData.full;
                }
            } else if (event.appointments.length > 1 && event.appointments.length >= maximumAppointments) {
                remark = '[' + event.appointments.length + '/' + maximumAppointments + ']';
            }

            let editable = _calendar.props.action === 'view' && event.appointments.length > 0;
            if (isResourceOverview) editable = false;

            let constraints = {};
            if (!editable) {
                // constraints.resourceIds = [event.agenda];
                constraints.resourceIds = [resourceId];
            }

            let isDummy = false;
            if (modusParams.selected) {
                Object.keys(modusParams.selected).forEach(s => {
                    if (s === event.from) isDummy = true;
                });
            }

            let mainEventId = uuidv4();

            let start = moment(event.from);
            if (event.reservedMinutesBefore) {
                start = start.subtract(event.reservedMinutesBefore, 'minutes');
            }

            let end = moment(event.till);
            if (event.reservedMinutesAfter) {
                end = end.add(event.reservedMinutesAfter, 'minutes');
            }

            events.push({
                id: mainEventId,
                // resourceId: event.agenda,
                resourceId: resourceId,
                constraint: constraints,
                title: remark,
                // start: event.from,
                start: start.format('YYYY-MM-DD HH:mm:ss'),
                // end: event.till,
                end: end.format('YYYY-MM-DD HH:mm:ss'),
                borderColor: color,
                editable: isResourceOverview ? false : true,
                url: url,
                extendedProps: {
                    addUrl: addUrl,
                    appointments: event.appointments,
                    blocked: event.blocked,
                    blockReason: event.blockReason,
                    category: event.category,
                    dayAppointment: null,
                    full: full,
                    subcategory: event.subcategory,
                    slotId: event.id,
                    publicHoliday: event.publicHoliday,
                    isHomeVisit: event.homeVisit,
                    // resourceId: event.agenda,
                    resourceId: resourceId,
                    isException: event.id ? event.id.indexOf('ex_') > -1 : false,
                    extra: event.extra ? event.extra : false,
                    priority: event.priority,
                    isBreakHour: event.isBreakHour,
                    repeatSequence: event.repeatSequence,
                    locked: event.locked,
                    lockedBy: event.lockedBy,
                    maximumAppointments: maximumAppointments,
                    appointmentsBookedOnSlot: event.bookedOnSlot,
                    initialAppointmentsPerSlot: event.initialAppointmentsPerSlot,
                    isDummy: isDummy,
                    mainEventId: mainEventId,
                    initialStart: event.from,
                    initialEnd: event.till,
                    hasReservedMinutes: event.reservedMinutesBefore || event.reservedMinutesAfter,
                    reservedMinutesBefore: event.reservedMinutesBefore,
                    reservedMinutesAfter: event.reservedMinutesAfter
                }
            });
        });
    }

    if (dayAppointments && dayAppointments.length > 0) {
        dayAppointments.forEach(event => events.push({
            id: uuidv4(),
            resourceId: agenda ? agenda : event.agenda,
            title: event.title,
            start: event.date,
            allDay: true,
            url: event.publicHoliday ? reverse(routes.holidays.edit, { id: event.publicHoliday }) : reverse(routes.dayappointments.edit, { id: event.id }),
            extendedProps: {
                blocked: event.blocked,
                dayAppointment: event,
                appointments: [],
                resourceId: event.agenda,
            }
        }));
    }

    if (notes && notes.length > 0) {
        notes.forEach(event => events.push({
            id: uuidv4(),
            title: event.note,
            resourceId: event.agendaId,
            constraints: {
                resourceIds: [event.agendaId]
            },
            start: event.start,
            end: event.end,
            allDay: event.allDay,
            borderColor: '#CCCCCC',
            url: reverse(routes.notes.edit, { id: event.id, agenda: event.agendaId }),
            editable: true,
            extendedProps: {
                blocked: true,
                isNote: true,
                note: event,
                appointments: []
            }
        }));
    }

    if (lastminutes && lastminutes.length > 0) {
        lastminutes.forEach(item => {
            events.push({
                id: uuidv4(),
                title: item.template.title,
                resourceId: item.agendaId,
                constraints: {
                    resourceIds: [item.agendaId]
                },
                start: item.start,
                end: item.end,
                allDay: false,
                borderColor: item.template.color,
                url: reverse(routes.settings.arrangements.lastminutes.edit, { id: item.id }),
                editable: false,
                extendedProps: {
                    type: 'last-minute',
                    lastminute: item,
                    blocked: true,
                    blockReason: 'last-minute',
                    appointments: []
                }
            });
        });
    }

    return events;
}

export const UtilCalendarEvent = {
    renderEvents,
    init
};